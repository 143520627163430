unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  XData.Web.Client, XData.Web.Connection, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, WEBLib.ExtCtrls,
  VCL.TMSFNCCustomControl, VCL.TMSFNCHTMLText, VCL.TMSFNCLabelEdit, WEBLib.XLSX,
  Types, Vcl.Grids, WEBLib.Grids, Vcl.Imaging.pngimage, Vcl.Mask, WEBLib.Mask;

type
  TForm1 = class(TForm)
    btnLogin: TButton;
    XDataWebConnection1: TXDataWebConnection;
    XDataWebClient1: TXDataWebClient;
    WebTimerXdataConnection: TTimer;
    lbXdataConnected: TLabel;
    WebXLSX1: TXLSX;
    lbFelinloggningMessage: TLabel;
    WebLabel1: TLabel;
    WebLabel3: TLabel;
    edUserName: TEdit;
    edPassword: TMaskEdit;
    WebPanel1: TPanel;
    WebImageControl1: TImageControl;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel2: TLabel;
    WebTimerHideLabels: TTimer;
    procedure btnLoginClick(Sender: TObject);
    procedure WebTimerXdataConnectionTimer(Sender: TObject);
    procedure XDataWebConnection1Error(Error: TXDataWebConnectionError);
    procedure XDataWebConnection1Connect(Sender: TObject);
    procedure WebTimerHideLabelsTimer(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

uses unit2;

procedure TForm1.btnLoginClick(Sender: TObject);
var
  newform:TForm2;

  procedure AfterShowModal(AValue:TModalResult);
  begin
    document.location.reload(true);  //reload webpage. login and password edit boxes are cleared
  end;

  procedure AfterCreate(AForm:TObject);
  begin
    if (edUserName.text ='ambra') then (AForm as TForm2).setCustomer:=1107;
    if (edUserName.text ='lundscience') then (AForm as TForm2).setCustomer:=1104;
    if (edUserName.text ='grimman') then (AForm as TForm2).setCustomer:=1085;

  end;

begin
 if ((edUserName.text ='ambra') and (edPassword.text = 'delta')) or
     ((edUserName.text ='grimman') and (edPassword.text = 'alfa1')) or
     ((edUserName.text ='lundscience') and (edPassword.text = 'service')) or
     ((edUserName.text ='lundscience') and (edPassword.text = 'RIIM'))
  then begin
    lbFelinloggningMessage.visible:=false;
    if ((edUserName.text ='ambra') and (edPassword.text = 'delta')) or
       ((edUserName.text ='grimman') and (edPassword.text = 'alfa1'))
    then begin
      newform:=TForm2.CreateNew(@AfterCreate);
      newform.ShowModal(@AfterShowModal);
    end;
  end else begin
    lbFelinloggningMessage.visible:=true;
    WebTimerHideLabels.Enabled:=true;
  end;
end;

procedure TForm1.WebTimerHideLabelsTimer(Sender: TObject);
begin
  WebTimerHideLabels.Enabled:=false;
  lbFelinloggningMessage.visible:=false;
end;

procedure TForm1.WebTimerXdataConnectionTimer(Sender: TObject);
begin
  WebTimerXdataConnection.enabled:=false;
  XDataWebConnection1.Connected:=true;
end;

procedure TForm1.XDataWebConnection1Connect(Sender: TObject);
begin
  lbXdataConnected.Caption:= 'Xdata connected';
end;

procedure TForm1.XDataWebConnection1Error(Error: TXDataWebConnectionError);
begin
  lbXdataConnected.Caption:= 'Xdata could not connect';
end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbXdataConnected := TLabel.Create(Self);
  lbFelinloggningMessage := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  btnLogin := TButton.Create(Self);
  edUserName := TEdit.Create(Self);
  edPassword := TMaskEdit.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  XDataWebConnection1 := TXDataWebConnection.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);
  WebTimerXdataConnection := TTimer.Create(Self);
  WebXLSX1 := TXLSX.Create(Self);
  WebTimerHideLabels := TTimer.Create(Self);

  lbXdataConnected.BeforeLoadDFMValues;
  lbFelinloggningMessage.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  edUserName.BeforeLoadDFMValues;
  edPassword.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  XDataWebConnection1.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  WebTimerXdataConnection.BeforeLoadDFMValues;
  WebXLSX1.BeforeLoadDFMValues;
  WebTimerHideLabels.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 1024;
    Height := 825;
    Caption := 'Lund Science IMD portal';
    lbXdataConnected.SetParentComponent(Self);
    lbXdataConnected.Name := 'lbXdataConnected';
    lbXdataConnected.Left := 32;
    lbXdataConnected.Top := 736;
    lbXdataConnected.Width := 123;
    lbXdataConnected.Height := 20;
    lbXdataConnected.Caption := 'lbXdataConnected';
    lbXdataConnected.HeightPercent := 100.000000000000000000;
    lbXdataConnected.WidthPercent := 100.000000000000000000;
    lbFelinloggningMessage.SetParentComponent(Self);
    lbFelinloggningMessage.Name := 'lbFelinloggningMessage';
    lbFelinloggningMessage.Left := 344;
    lbFelinloggningMessage.Top := 511;
    lbFelinloggningMessage.Width := 270;
    lbFelinloggningMessage.Height := 28;
    lbFelinloggningMessage.Caption := 'Fel inloggning. F'#246'rs'#246'k igen';
    lbFelinloggningMessage.Color := clBurlywood;
    lbFelinloggningMessage.ElementLabelClassName := 'form-check-label';
    lbFelinloggningMessage.Font.Charset := DEFAULT_CHARSET;
    lbFelinloggningMessage.Font.Color := clWindowText;
    lbFelinloggningMessage.Font.Height := -23;
    lbFelinloggningMessage.Font.Name := 'Tahoma';
    lbFelinloggningMessage.Font.Style := [];
    lbFelinloggningMessage.HeightStyle := ssAuto;
    lbFelinloggningMessage.HeightPercent := 100.000000000000000000;
    lbFelinloggningMessage.ParentFont := False;
    lbFelinloggningMessage.Visible := False;
    lbFelinloggningMessage.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 228;
    WebLabel1.Top := 298;
    WebLabel1.Width := 134;
    WebLabel1.Height := 35;
    WebLabel1.Caption := 'Anv'#228'ndare';
    WebLabel1.Color := clBurlywood;
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clBlack;
    WebLabel1.Font.Height := -29;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 228;
    WebLabel3.Top := 358;
    WebLabel3.Width := 116;
    WebLabel3.Height := 35;
    WebLabel3.Caption := 'L'#246'senord';
    WebLabel3.Color := clBurlywood;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clBlack;
    WebLabel3.Font.Height := -29;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 416;
    btnLogin.Top := 442;
    btnLogin.Width := 161;
    btnLogin.Height := 41;
    btnLogin.Caption := 'Logga in';
    btnLogin.Font.Charset := DEFAULT_CHARSET;
    btnLogin.Font.Color := clWindowText;
    btnLogin.Font.Height := -23;
    btnLogin.Font.Name := 'Segoe UI';
    btnLogin.Font.Style := [];
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.ParentFont := False;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
    edUserName.SetParentComponent(Self);
    edUserName.Name := 'edUserName';
    edUserName.Left := 386;
    edUserName.Top := 298;
    edUserName.Width := 228;
    edUserName.Height := 40;
    edUserName.ChildOrder := 7;
    edUserName.Color := clSilver;
    edUserName.Font.Charset := DEFAULT_CHARSET;
    edUserName.Font.Color := clWindowText;
    edUserName.Font.Height := -24;
    edUserName.Font.Name := 'Segoe UI';
    edUserName.Font.Style := [];
    edUserName.HeightPercent := 100.000000000000000000;
    edUserName.ParentFont := False;
    edUserName.WidthPercent := 100.000000000000000000;
    edPassword.SetParentComponent(Self);
    edPassword.Name := 'edPassword';
    edPassword.Left := 386;
    edPassword.Top := 358;
    edPassword.Width := 228;
    edPassword.Height := 40;
    edPassword.Color := clSilver;
    edPassword.Font.Charset := DEFAULT_CHARSET;
    edPassword.Font.Color := clWindowText;
    edPassword.Font.Height := -24;
    edPassword.Font.Name := 'Segoe UI';
    edPassword.Font.Style := [];
    edPassword.ParentFont := False;
    edPassword.PasswordChar := '*';
    edPassword.TabOrder := 2;
    edPassword.Text := '';
    edPassword.HeightPercent := 100.000000000000000000;
    edPassword.WidthPercent := 100.000000000000000000;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1024;
    WebPanel1.Height := 61;
    WebPanel1.Align := alTop;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 22;
    WebPanel1.Color := clBlanchedalmond;
    WebPanel1.ShowCaption := False;
    WebPanel1.TabOrder := 3;
    WebImageControl1.SetParentComponent(WebPanel1);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 3;
    WebImageControl1.Top := 4;
    WebImageControl1.Width := 57;
    WebImageControl1.Height := 54;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 18;
    WebImageControl1.Picture.LoadFromFile('Unit1.WebPanel1.WebImageControl1.Picture.png');
    WebLabel4.SetParentComponent(WebPanel1);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 159;
    WebLabel4.Top := 42;
    WebLabel4.Width := 199;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Lund Science AB,  2024 ver 1.12_2';
    WebLabel4.Color := clBurlywood;
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clBlack;
    WebLabel4.Font.Height := -13;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebPanel1);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 90;
    WebLabel5.Top := 1;
    WebLabel5.Width := 377;
    WebLabel5.Height := 35;
    WebLabel5.Caption := 'Lund Science IMD Web portal';
    WebLabel5.Color := clBurlywood;
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clBlack;
    WebLabel5.Font.Height := -29;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 979;
    WebLabel2.Top := 38;
    WebLabel2.Width := 8;
    WebLabel2.Height := 20;
    WebLabel2.Caption := '6';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    XDataWebConnection1.SetParentComponent(Self);
    XDataWebConnection1.Name := 'XDataWebConnection1';
    XDataWebConnection1.URL := 'https://lslabtest.dyndns-server.com:8035/';
    SetEvent(XDataWebConnection1, Self, 'OnConnect', 'XDataWebConnection1Connect');
    SetEvent(XDataWebConnection1, Self, 'OnError', 'XDataWebConnection1Error');
    XDataWebConnection1.Left := 912;
    XDataWebConnection1.Top := 352;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Connection := XDataWebConnection1;
    XDataWebClient1.Left := 912;
    XDataWebClient1.Top := 424;
    WebTimerXdataConnection.SetParentComponent(Self);
    WebTimerXdataConnection.Name := 'WebTimerXdataConnection';
    SetEvent(WebTimerXdataConnection, Self, 'OnTimer', 'WebTimerXdataConnectionTimer');
    WebTimerXdataConnection.Left := 920;
    WebTimerXdataConnection.Top := 280;
    WebXLSX1.SetParentComponent(Self);
    WebXLSX1.Name := 'WebXLSX1';
    WebXLSX1.Left := 912;
    WebXLSX1.Top := 496;
    WebTimerHideLabels.SetParentComponent(Self);
    WebTimerHideLabels.Name := 'WebTimerHideLabels';
    WebTimerHideLabels.Enabled := False;
    WebTimerHideLabels.Interval := 5000;
    SetEvent(WebTimerHideLabels, Self, 'OnTimer', 'WebTimerHideLabelsTimer');
    WebTimerHideLabels.Left := 928;
    WebTimerHideLabels.Top := 96;
  finally
    lbXdataConnected.AfterLoadDFMValues;
    lbFelinloggningMessage.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    edUserName.AfterLoadDFMValues;
    edPassword.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    XDataWebConnection1.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
    WebTimerXdataConnection.AfterLoadDFMValues;
    WebXLSX1.AfterLoadDFMValues;
    WebTimerHideLabels.AfterLoadDFMValues;
  end;
end;

end.