unit Unit2;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Types, Vcl.Grids, WEBLib.Grids, WEBLib.ExtCtrls;

type
  T_utilityDescriptor = record
    sensortype        : integer;
    metertype         : integer;
    attachedto        : integer;
  end;


type
  TForm2 = class(TForm)
    btnExitPage: TButton;
    btnDataToday: TButton;
    btnDataOtherDate: TButton;
    btnDataPeriod: TButton;
    btnSaveToExcel: TButton;
    WebStringGrid1: TStringGrid;
    WebMemo1: TMemo;
    btnClearMemo: TButton;
    cbVisaMemo: TCheckBox;
    WebDateTimePicker1: TDateTimePicker;
    WebDateTimePicker2: TDateTimePicker;
    WebLabel4: TLabel;
    WebDateTimePicker3: TDateTimePicker;
    edExcelFilnamn: TEdit;
    RadioGroupBuildings: TRadioGroup;
    RadioGroupUtilities: TRadioGroup;
    WebTimerUtilityData: TTimer;
    WebTimerAtPageStart: TTimer;
    WebPanel1: TPanel;
    WebRadioGroup1: TRadioGroup;
    WebRadioGroup2: TRadioGroup;
    procedure btnExitPageClick(Sender: TObject);
    procedure WebTimerUtilityDataTimer(Sender: TObject);
    procedure WebTimerAtPageStartTimer(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure cbVisaMemoClick(Sender: TObject);
    procedure btnDataTodayClick(Sender: TObject);
    procedure btnDataOtherDateClick(Sender: TObject);
    procedure btnClearMemoClick(Sender: TObject);
    procedure btnDataPeriodClick(Sender: TObject);
    procedure btnSaveToExcelClick(Sender: TObject);
    procedure WebRadioGroup1Change(Sender: TObject);
    procedure SetPeriodDates;
    procedure WebRadioGroup2Change(Sender: TObject);
    procedure WebDateTimePicker3Change(Sender: TObject);

  private
    { Private declarations }
    var
      fid_customer            :integer;
    procedure UpdateBuildings(id_customer:integer);
    procedure UpdateUtility(id_customer:integer);


  public
    { Public declarations }
    property setCustomer:integer write fid_customer;

  protected procedure LoadDFMValues; override; end;

var
  Form2: TForm2;

implementation

{$R *.dfm}

uses Unit1, WEBLib.WebTools, System.UITypes, XData.Web.Client, DateUtils;

var
    utility   : array[0..29] of T_utilityDescriptor;    //max 29 utilites
    building  : array[0..99] of integer;                //max 100 buildings
    customer  : array[0..99] of integer;                //max 100 customers




//------------------------------------------------------------------------------
procedure TForm2.UpdateBuildings(id_customer: integer);
var i:integer;

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;
  begin
    RadioGroupBuildings.items.Clear;
    WebStringGrid1.colcount:=2;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      //      WebStringGrid1.cells[0,i]:=SplittedLine[0];
//      WebStringGrid1.cells[1,i]:=SplittedLine[1];
      if (i > 0) then begin
        RadioGroupBuildings.Items.Add(SplittedLine[1]); //namn fastighet + ort
        building[i-1]:= strtoint(splittedLine[0]);      //id_building
      end;
      webmemo1.Lines.Add('RadioGroupBuildings: i, value ' + inttostr(i) + '; ' + inttostr(building[i-1]));

    end;

    if nElements1 > 0 then begin
      RadioGroupBuildings.Items.Add('Alla');
      building[nElements1+1]:=0;
    end;
//    WebStringGrid1.rowcount:=nElements1+1;
    RadioGroupBuildings.Font.Size:=9;
    WebTimerUtilityData.enabled:=true;

  end;
begin
  webmemo1.Lines.Add('Here. id-customer: ' + inttostr(id_customer));
  Form1.XDataWebClient1.RawInvoke('ICustomerService.GetBuildingList',[id_customer],@OnResult);
end;




procedure TForm2.UpdateUtility(id_customer: integer);

var i:integer;

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;

  begin
    RadioGroupUtilities.items.Clear;
    WebStringGrid1.colcount:=5;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      if (i > 0) then begin //first line is the field names
        RadioGroupUtilities.Items.Add(SplittedLine[4]); //namn fastighet + ort
        utility[i-1].sensortype:=strtoint(SplittedLine[2]);  //inidex must start at 0
        utility[i-1].attachedto:=strtoint(SplittedLine[3]);
      end;
    end;
    RadioGroupUtilities.Font.Size:=9;
  end;

begin
  webmemo1.Lines.Add('Here. id-customer: ' + inttostr(id_customer));
  Form1.XDataWebClient1.RawInvoke('ICustomerService.GetUtilityList',[id_customer],@OnResult);
end;


procedure TForm2.btnClearMemoClick(Sender: TObject);
begin
  WebMemo1.clear;
end;

//---------------------------------------------------------------------------
procedure TForm2.btnDataOtherDateClick(Sender: TObject);
//brief: get data of selected utility at selected date

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;

  begin
    WebStringGrid1.colcount:=7;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      WebStringGrid1.cells[0,i]:=SplittedLine[0];  // id_sensor
      WebStringGrid1.cells[1,i]:=SplittedLine[1];  // radio
      WebStringGrid1.cells[2,i]:=SplittedLine[2];  // datum
      WebStringGrid1.cells[3,i]:=SplittedLine[3];  // klockan
      WebStringGrid1.cells[4,i]:=SplittedLine[4];  // data senaste
      WebStringGrid1.cells[5,i]:=SplittedLine[5];  // lgh namn
      WebStringGrid1.cells[6,i]:=SplittedLine[6];  // byggnad
    end;

    WebStringGrid1.ColWidths[0]:=100;
    WebStringGrid1.ColWidths[1]:=100;
    WebStringGrid1.ColWidths[2]:=100;
    WebStringGrid1.ColWidths[3]:=100;
    WebStringGrid1.ColWidths[4]:=100;
    WebStringGrid1.ColWidths[5]:=100;
    WebStringGrid1.ColWidths[6]:=100;

    WebstringGrid1.FixedFont.Size:=9;
    WebstringGrid1.FixedFont.Style := [TFontStyle.fsBold];

    webmemo1.Lines.Add(GreetResult);
  end;

var
  id_customer:integer;
  id_building:integer;
  id_type_of_sensor:integer;
  id_sensor_attached_to:integer;
  selectedDate:string;
  myDate : TDateTime;

begin
  btnDataToday.color:= clNone;
  btnDataOtherDate.color:=clGreen;
  btnDataPeriod.color:=clNone;
  btnSaveToExcel.color:=clNone;

  myDate:= WebDateTimePicker1.date;
  webmemo1.lines.add('Datum: ' + FormatDateTime('yyyy-mm-dd', myDate));
  selectedDate:=FormatDateTime('yyyy-mm-dd', myDate);
  id_customer:=fid_customer;
  id_building:=building[RadioGroupBuildings.ItemIndex];
  id_type_of_sensor:= utility[RadioGroupUtilities.ItemIndex].sensortype;
  id_sensor_attached_to:= utility[RadioGroupUtilities.ItemIndex].attachedto;
  webmemo1.lines.add('customer,building, type,attached: ' + inttostr(id_customer) + '; ' +  inttostr(id_building) + '; '+ inttostr(id_type_of_sensor) + '; ' + inttostr(id_sensor_attached_to));

  webstringgrid1.clear;
  Form1.XDataWebClient1.RawInvoke('ICustomerService.GetDataSelectedDate',[id_customer, id_building, id_type_of_sensor, id_sensor_attached_to,selectedDate],@OnResult);
end;


//---------------------------------------------------------------------------
procedure TForm2.btnDataPeriodClick(Sender: TObject);
//brief: get data of selected utility at selected date

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;
  begin
    WebStringGrid1.colcount:=9;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      WebStringGrid1.cells[0,i]:=SplittedLine[0];  // id_sensor
      WebStringGrid1.cells[1,i]:=SplittedLine[1];  // radio
      WebStringGrid1.cells[2,i]:=SplittedLine[2];  // startdata
      WebStringGrid1.cells[3,i]:=SplittedLine[3];  // slutdata
      WebStringGrid1.cells[4,i]:=SplittedLine[4];  // netto data
      WebStringGrid1.cells[5,i]:=SplittedLine[5];  // id lgh
      WebStringGrid1.cells[6,i]:=SplittedLine[6];  // id-obj
      WebStringGrid1.cells[7,i]:=SplittedLine[7];  // lgh namn
      WebStringGrid1.cells[8,i]:=SplittedLine[8];  // lgh namn
    end;

    WebStringGrid1.ColWidths[0]:=100;
    WebStringGrid1.ColWidths[1]:=100;
    WebStringGrid1.ColWidths[2]:=100;
    WebStringGrid1.ColWidths[3]:=100;
    WebStringGrid1.ColWidths[4]:=100;
    WebStringGrid1.ColWidths[5]:=100;
    WebStringGrid1.ColWidths[6]:=100;
    WebStringGrid1.ColWidths[7]:=100;
    WebStringGrid1.ColWidths[8]:=100;

    WebstringGrid1.FixedFont.Size:=9;
    WebstringGrid1.FixedFont.Style := [TFontStyle.fsBold];

    webmemo1.Lines.Add(GreetResult);
  end;

var
  id_customer:integer;
  id_building:integer;
  id_type_of_sensor:integer;
  id_sensor_attached_to:integer;
  fLowDate, fHighDate:string;
  myDate : TDateTime;

begin
  btnDataToday.color:= clNone;
  btnDataOtherDate.color:=clNone;
  btnDataPeriod.color:=clGreen;
  btnSaveToExcel.color:=clNone;

  myDate:= WebDateTimePicker2.date;
  fLowDate:=FormatDateTime('yyyy-mm-dd', myDate);

  myDate:= WebDateTimePicker3.date;
  fHighDate:=FormatDateTime('yyyy-mm-dd', myDate);

//  fLowDate:=edStartDate.text;
//  fHighDate:=edEndDate.text;
  id_customer:=fid_customer;
  id_building:=building[RadioGroupBuildings.ItemIndex];
  id_type_of_sensor:= utility[RadioGroupUtilities.ItemIndex].sensortype;
  id_sensor_attached_to:= utility[RadioGroupUtilities.ItemIndex].attachedto;
//  webmemo1.lines.add('customer,building, type,attached: ' + inttostr(id_customer) + '; ' +  inttostr(id_building) + '; '+ inttostr(id_type_of_sensor) + '; ' + inttostr(id_sensor_attached_to));

  webstringgrid1.clear;
  Form1.XDataWebClient1.RawInvoke('ICustomerService.GetNetData',[id_customer, id_building, id_type_of_sensor, id_sensor_attached_to, fLowDate, fHighDate],@OnResult);
end;

//---------------------------------------------------------------------------
procedure TForm2.btnDataTodayClick(Sender: TObject);

  procedure OnResult(Response:TXdataClientResponse);
  var
    GreetResult: string;
    Splitted:TArray<string>;
    SplittedLine:TArray<string>;
    i, nElements1,nElements2:integer;
  begin
    WebStringGrid1.colcount:=7;
//    WebStringGrid1.rowcount:=500;
    GreetResult:=string(TJSObject(Response.Result)['value']);
    Splitted :=GreetResult.Split([#13#10]);
    nElements1 := high(Splitted)-1; // starts at [0]
    for i := 0 to nElements1 do
    begin
      SplittedLine:=Splitted[i].Split(';');
      nElements2 := high(SplittedLine)-1;
      WebStringGrid1.cells[0,i]:=SplittedLine[0];  // id_sensor
      WebStringGrid1.cells[1,i]:=SplittedLine[1];  // radio
      WebStringGrid1.cells[2,i]:=SplittedLine[2];  // datum
      WebStringGrid1.cells[3,i]:=SplittedLine[3];  // klockan
      WebStringGrid1.cells[4,i]:=SplittedLine[4];  // data senaste
      WebStringGrid1.cells[5,i]:=SplittedLine[5];  // lgh namn
      WebStringGrid1.cells[6,i]:=SplittedLine[6];  // Byggnad
    end;

    WebStringGrid1.ColWidths[0]:=100;
    WebStringGrid1.ColWidths[1]:=100;
    WebStringGrid1.ColWidths[2]:=100;
    WebStringGrid1.ColWidths[3]:=100;
    WebStringGrid1.ColWidths[4]:=100;
    WebStringGrid1.ColWidths[5]:=100;
    WebStringGrid1.ColWidths[6]:=100;

    WebstringGrid1.FixedFont.Size:=9;
    WebstringGrid1.FixedFont.Style := [TFontStyle.fsBold];

    webmemo1.Lines.Add(GreetResult);
  end;

var
  id_customer:integer;
  id_building:integer;
  id_type_of_sensor:integer;
  id_sensor_attached_to:integer;

begin
  btnDataToday.color:= clGreen;
  btnDataOtherDate.color:=clNone;
  btnDataPeriod.color:=clNone;
  btnSaveToExcel.color:=clNone;

  id_customer:=fid_customer;
  id_building:=building[RadioGroupBuildings.ItemIndex];
  id_type_of_sensor:= utility[RadioGroupUtilities.ItemIndex].sensortype;
  id_sensor_attached_to:= utility[RadioGroupUtilities.ItemIndex].attachedto;
  webmemo1.lines.add('customer,building, type,attached: ' + inttostr(id_customer) + '; ' +  inttostr(id_building) + '; '+ inttostr(id_type_of_sensor) + '; ' + inttostr(id_sensor_attached_to));

  webstringgrid1.clear;
  Form1.XDataWebClient1.RawInvoke('ICustomerService.GetDataToday',[id_customer, id_building, id_type_of_sensor, id_sensor_attached_to],@OnResult);
end;


procedure TForm2.btnExitPageClick(Sender: TObject);
begin
  Close;
end;

procedure TForm2.btnSaveToExcelClick(Sender: TObject);
begin
  with Form1.webxlsx1 do begin
    grid:=webstringgrid1;
    gridstartcol:=0;
    gridstartrow:=0;
    save(edExcelFilnamn.text);
  end;

end;

procedure TForm2.cbVisaMemoClick(Sender: TObject);
begin
  if cbVisaMemo.Checked then begin
    WebMemo1.visible:=true;
    btnClearMemo.visible:=true;
  end else begin
    WebMemo1.visible:=false;
    btnClearMemo.visible:=false;
  end;
end;

procedure TForm2.WebDateTimePicker3Change(Sender: TObject);
begin
  if (WebDateTimePicker3.Date < WebDateTimePicker2.Date) then
  begin
    ShowMessage('Slutdatum kan inte vara mindre än startdatum');
    WebDateTimePicker3.Date := WebDateTimePicker2.Date;
  end;
end;

procedure TForm2.WebFormShow(Sender: TObject);
begin
  webmemo1.lines.add('customer: ' + inttostr(fid_customer));
  WebTimerAtPageStart.enabled:=true;
end;

procedure TForm2.SetPeriodDates;
var
  Year,Month,Day : word;
  iYear,iMonth:integer;
  DateHigh,DateLow: TDateTime;

begin
  iYear:=WebRadioGroup2.ItemIndex+2021;
  iMonth:=WebRadioGroup1.Itemindex+1;
  DecodeDate(Now,Year,Month,Day);

  if ((MonthOfTheYear(Now) = iMonth) and (Year = iYear) ) then
    DateHigh:= Now-1
  else
    DateHigh:= EndOfAMonth(iYear,iMonth);

  if WebRadioGroup1.Itemindex = 0 then
    DateLow:= EndOfAMonth(iYear-1,12)+1 //if january
  else
    DateLow:= EndOfAMonth(iYear,iMonth-1)+1;

  WebMemo1.lines.add('High: ' + DateToStr(DateHigh));
  WebMemo1.lines.add('Low:  ' + DateToStr(DateLow));

  WebDateTimePicker2.Date:=DateLow;
  WebDateTimePicker3.Date:=DateHigh;
end;


procedure TForm2.WebRadioGroup1Change(Sender: TObject);
//brief: updates selected period
begin
  SetPeriodDates;
end;


procedure TForm2.WebRadioGroup2Change(Sender: TObject);
begin
  SetPeriodDates;
end;

procedure TForm2.WebTimerAtPageStartTimer(Sender: TObject);
begin
  WebTimerAtPageStart.enabled:=false;
  UpdateBuildings(fid_customer);
end;

procedure TForm2.WebTimerUtilityDataTimer(Sender: TObject);
begin
  WebTimerUtilityData.Enabled:=false;
  UpdateUtility(fid_customer);
end;

procedure TForm2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel4 := TLabel.Create(Self);
  btnExitPage := TButton.Create(Self);
  btnDataToday := TButton.Create(Self);
  btnDataOtherDate := TButton.Create(Self);
  btnDataPeriod := TButton.Create(Self);
  btnSaveToExcel := TButton.Create(Self);
  WebStringGrid1 := TStringGrid.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  btnClearMemo := TButton.Create(Self);
  cbVisaMemo := TCheckBox.Create(Self);
  WebDateTimePicker1 := TDateTimePicker.Create(Self);
  WebDateTimePicker2 := TDateTimePicker.Create(Self);
  WebDateTimePicker3 := TDateTimePicker.Create(Self);
  edExcelFilnamn := TEdit.Create(Self);
  RadioGroupBuildings := TRadioGroup.Create(Self);
  RadioGroupUtilities := TRadioGroup.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebRadioGroup1 := TRadioGroup.Create(Self);
  WebRadioGroup2 := TRadioGroup.Create(Self);
  WebTimerUtilityData := TTimer.Create(Self);
  WebTimerAtPageStart := TTimer.Create(Self);

  WebLabel4.BeforeLoadDFMValues;
  btnExitPage.BeforeLoadDFMValues;
  btnDataToday.BeforeLoadDFMValues;
  btnDataOtherDate.BeforeLoadDFMValues;
  btnDataPeriod.BeforeLoadDFMValues;
  btnSaveToExcel.BeforeLoadDFMValues;
  WebStringGrid1.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  btnClearMemo.BeforeLoadDFMValues;
  cbVisaMemo.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  WebDateTimePicker2.BeforeLoadDFMValues;
  WebDateTimePicker3.BeforeLoadDFMValues;
  edExcelFilnamn.BeforeLoadDFMValues;
  RadioGroupBuildings.BeforeLoadDFMValues;
  RadioGroupUtilities.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebRadioGroup1.BeforeLoadDFMValues;
  WebRadioGroup2.BeforeLoadDFMValues;
  WebTimerUtilityData.BeforeLoadDFMValues;
  WebTimerAtPageStart.BeforeLoadDFMValues;
  try
    Name := 'Form2';
    Width := 1600;
    Height := 900;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -12;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 613;
    WebLabel4.Top := 85;
    WebLabel4.Width := 18;
    WebLabel4.Height := 25;
    WebLabel4.Caption := 'till';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -18;
    WebLabel4.Font.Name := 'Segoe UI';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    btnExitPage.SetParentComponent(Self);
    btnExitPage.Name := 'btnExitPage';
    btnExitPage.Left := 1461;
    btnExitPage.Top := 24;
    btnExitPage.Width := 137;
    btnExitPage.Height := 25;
    btnExitPage.Caption := 'Tillbaka';
    btnExitPage.HeightPercent := 100.000000000000000000;
    btnExitPage.WidthPercent := 100.000000000000000000;
    SetEvent(btnExitPage, Self, 'OnClick', 'btnExitPageClick');
    btnDataToday.SetParentComponent(Self);
    btnDataToday.Name := 'btnDataToday';
    btnDataToday.Left := 36;
    btnDataToday.Top := 24;
    btnDataToday.Width := 137;
    btnDataToday.Height := 25;
    btnDataToday.Caption := 'Data idag';
    btnDataToday.ChildOrder := 1;
    btnDataToday.HeightPercent := 100.000000000000000000;
    btnDataToday.WidthPercent := 100.000000000000000000;
    SetEvent(btnDataToday, Self, 'OnClick', 'btnDataTodayClick');
    btnDataOtherDate.SetParentComponent(Self);
    btnDataOtherDate.Name := 'btnDataOtherDate';
    btnDataOtherDate.Left := 253;
    btnDataOtherDate.Top := 24;
    btnDataOtherDate.Width := 137;
    btnDataOtherDate.Height := 25;
    btnDataOtherDate.Caption := 'Data annan dag';
    btnDataOtherDate.ChildOrder := 1;
    btnDataOtherDate.HeightPercent := 100.000000000000000000;
    btnDataOtherDate.WidthPercent := 100.000000000000000000;
    SetEvent(btnDataOtherDate, Self, 'OnClick', 'btnDataOtherDateClick');
    btnDataPeriod.SetParentComponent(Self);
    btnDataPeriod.Name := 'btnDataPeriod';
    btnDataPeriod.Left := 545;
    btnDataPeriod.Top := 24;
    btnDataPeriod.Width := 137;
    btnDataPeriod.Height := 25;
    btnDataPeriod.Caption := 'Data f'#246'r period';
    btnDataPeriod.ChildOrder := 1;
    btnDataPeriod.HeightPercent := 100.000000000000000000;
    btnDataPeriod.WidthPercent := 100.000000000000000000;
    SetEvent(btnDataPeriod, Self, 'OnClick', 'btnDataPeriodClick');
    btnSaveToExcel.SetParentComponent(Self);
    btnSaveToExcel.Name := 'btnSaveToExcel';
    btnSaveToExcel.Left := 954;
    btnSaveToExcel.Top := 24;
    btnSaveToExcel.Width := 137;
    btnSaveToExcel.Height := 25;
    btnSaveToExcel.Caption := 'Spara till excel';
    btnSaveToExcel.ChildOrder := 1;
    btnSaveToExcel.HeightPercent := 100.000000000000000000;
    btnSaveToExcel.WidthPercent := 100.000000000000000000;
    SetEvent(btnSaveToExcel, Self, 'OnClick', 'btnSaveToExcelClick');
    WebStringGrid1.SetParentComponent(Self);
    WebStringGrid1.Name := 'WebStringGrid1';
    WebStringGrid1.Left := 8;
    WebStringGrid1.Top := 144;
    WebStringGrid1.Width := 709;
    WebStringGrid1.Height := 489;
    WebStringGrid1.ColCount := 12;
    WebStringGrid1.FixedCols := 0;
    WebStringGrid1.RowCount := 500;
    WebStringGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect,goFixedRowDefAlign];
    WebStringGrid1.TabOrder := 5;
    WebStringGrid1.FixedFont.Charset := DEFAULT_CHARSET;
    WebStringGrid1.FixedFont.Color := clWindowText;
    WebStringGrid1.FixedFont.Height := -18;
    WebStringGrid1.FixedFont.Name := 'Segoe UI';
    WebStringGrid1.FixedFont.Style := [];
    WebStringGrid1.RangeEdit.Max := 100.000000000000000000;
    WebStringGrid1.RangeEdit.Step := 1.000000000000000000;
    WebStringGrid1.HeightPercent := 100.000000000000000000;
    WebStringGrid1.WidthPercent := 100.000000000000000000;
    WebStringGrid1.ColWidths[0] := 64;
    WebStringGrid1.ColWidths[1] := 64;
    WebStringGrid1.ColWidths[2] := 64;
    WebStringGrid1.ColWidths[3] := 64;
    WebStringGrid1.ColWidths[4] := 64;
    WebStringGrid1.ColWidths[5] := 64;
    WebStringGrid1.ColWidths[6] := 64;
    WebStringGrid1.ColWidths[7] := 64;
    WebStringGrid1.ColWidths[8] := 64;
    WebStringGrid1.ColWidths[9] := 64;
    WebStringGrid1.ColWidths[10] := 64;
    WebStringGrid1.ColWidths[11] := 64;
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 674;
    WebMemo1.Width := 705;
    WebMemo1.Height := 159;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.Visible := False;
    WebMemo1.WidthPercent := 100.000000000000000000;
    btnClearMemo.SetParentComponent(Self);
    btnClearMemo.Name := 'btnClearMemo';
    btnClearMemo.Left := 733;
    btnClearMemo.Top := 719;
    btnClearMemo.Width := 145;
    btnClearMemo.Height := 41;
    btnClearMemo.Caption := 'Clear memo';
    btnClearMemo.ChildOrder := 5;
    btnClearMemo.HeightPercent := 100.000000000000000000;
    btnClearMemo.Visible := False;
    btnClearMemo.WidthPercent := 100.000000000000000000;
    SetEvent(btnClearMemo, Self, 'OnClick', 'btnClearMemoClick');
    cbVisaMemo.SetParentComponent(Self);
    cbVisaMemo.Name := 'cbVisaMemo';
    cbVisaMemo.Left := 733;
    cbVisaMemo.Top := 766;
    cbVisaMemo.Width := 236;
    cbVisaMemo.Height := 22;
    cbVisaMemo.Caption := 'Visa Memo-f'#228'ltet';
    cbVisaMemo.ChildOrder := 7;
    cbVisaMemo.HeightPercent := 100.000000000000000000;
    cbVisaMemo.WidthPercent := 100.000000000000000000;
    SetEvent(cbVisaMemo, Self, 'OnClick', 'cbVisaMemoClick');
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.Left := 254;
    WebDateTimePicker1.Top := 77;
    WebDateTimePicker1.Width := 136;
    WebDateTimePicker1.Height := 41;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 11;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 45567.730063796300000000;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
    WebDateTimePicker2.SetParentComponent(Self);
    WebDateTimePicker2.Name := 'WebDateTimePicker2';
    WebDateTimePicker2.Left := 471;
    WebDateTimePicker2.Top := 77;
    WebDateTimePicker2.Width := 136;
    WebDateTimePicker2.Height := 41;
    WebDateTimePicker2.BorderStyle := bsSingle;
    WebDateTimePicker2.ChildOrder := 11;
    WebDateTimePicker2.Color := clWhite;
    WebDateTimePicker2.Date := 45567.730063796300000000;
    WebDateTimePicker2.Role := '';
    WebDateTimePicker2.Text := '';
    WebDateTimePicker3.SetParentComponent(Self);
    WebDateTimePicker3.Name := 'WebDateTimePicker3';
    WebDateTimePicker3.Left := 645;
    WebDateTimePicker3.Top := 77;
    WebDateTimePicker3.Width := 136;
    WebDateTimePicker3.Height := 41;
    WebDateTimePicker3.BorderStyle := bsSingle;
    WebDateTimePicker3.ChildOrder := 11;
    WebDateTimePicker3.Color := clWhite;
    WebDateTimePicker3.Date := 45567.730063796300000000;
    WebDateTimePicker3.Role := '';
    WebDateTimePicker3.Text := '';
    SetEvent(WebDateTimePicker3, Self, 'OnChange', 'WebDateTimePicker3Change');
    edExcelFilnamn.SetParentComponent(Self);
    edExcelFilnamn.Name := 'edExcelFilnamn';
    edExcelFilnamn.Left := 913;
    edExcelFilnamn.Top := 77;
    edExcelFilnamn.Width := 240;
    edExcelFilnamn.Height := 40;
    edExcelFilnamn.ChildOrder := 2;
    edExcelFilnamn.Enabled := False;
    edExcelFilnamn.Font.Charset := DEFAULT_CHARSET;
    edExcelFilnamn.Font.Color := clWindowText;
    edExcelFilnamn.Font.Height := -16;
    edExcelFilnamn.Font.Name := 'Segoe UI';
    edExcelFilnamn.Font.Style := [];
    edExcelFilnamn.HeightPercent := 100.000000000000000000;
    edExcelFilnamn.ParentFont := False;
    edExcelFilnamn.Text := 'min_datafil.xlsx';
    edExcelFilnamn.WidthPercent := 100.000000000000000000;
    RadioGroupBuildings.SetParentComponent(Self);
    RadioGroupBuildings.Name := 'RadioGroupBuildings';
    RadioGroupBuildings.Left := 757;
    RadioGroupBuildings.Top := 144;
    RadioGroupBuildings.Width := 242;
    RadioGroupBuildings.Height := 553;
    RadioGroupBuildings.Caption := 'Hus';
    RadioGroupBuildings.ChildOrder := 5;
    RadioGroupBuildings.Columns := 1;
    RadioGroupBuildings.Font.Charset := DEFAULT_CHARSET;
    RadioGroupBuildings.Font.Color := clWindowText;
    RadioGroupBuildings.Font.Height := -15;
    RadioGroupBuildings.Font.Name := 'Segoe UI';
    RadioGroupBuildings.Font.Style := [];
    RadioGroupBuildings.ItemIndex := 0;
    RadioGroupBuildings.ParentFont := False;
    RadioGroupBuildings.Role := '';
    RadioGroupBuildings.TabOrder := 13;
    RadioGroupUtilities.SetParentComponent(Self);
    RadioGroupUtilities.Name := 'RadioGroupUtilities';
    RadioGroupUtilities.Left := 1017;
    RadioGroupUtilities.Top := 144;
    RadioGroupUtilities.Width := 313;
    RadioGroupUtilities.Height := 553;
    RadioGroupUtilities.Caption := 'Vad vill du se';
    RadioGroupUtilities.ChildOrder := 5;
    RadioGroupUtilities.Columns := 1;
    RadioGroupUtilities.Font.Charset := DEFAULT_CHARSET;
    RadioGroupUtilities.Font.Color := clWindowText;
    RadioGroupUtilities.Font.Height := -15;
    RadioGroupUtilities.Font.Name := 'Segoe UI';
    RadioGroupUtilities.Font.Style := [];
    RadioGroupUtilities.ItemIndex := 0;
    RadioGroupUtilities.ParentFont := False;
    RadioGroupUtilities.Role := '';
    RadioGroupUtilities.TabOrder := 14;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 1600;
    WebPanel1.Height := 10;
    WebPanel1.Align := alTop;
    WebPanel1.Caption := 'WebPanel1';
    WebPanel1.ChildOrder := 16;
    WebPanel1.Color := clBlue;
    WebPanel1.ShowCaption := False;
    WebPanel1.TabOrder := 15;
    WebRadioGroup1.SetParentComponent(Self);
    WebRadioGroup1.Name := 'WebRadioGroup1';
    WebRadioGroup1.Left := 1336;
    WebRadioGroup1.Top := 148;
    WebRadioGroup1.Width := 145;
    WebRadioGroup1.Height := 370;
    WebRadioGroup1.Caption := 'V'#228'lj period m'#229'nad';
    WebRadioGroup1.ChildOrder := 17;
    WebRadioGroup1.Columns := 1;
    WebRadioGroup1.Font.Charset := DEFAULT_CHARSET;
    WebRadioGroup1.Font.Color := clWindowText;
    WebRadioGroup1.Font.Height := -12;
    WebRadioGroup1.Font.Name := 'Segoe UI';
    WebRadioGroup1.Font.Style := [];
    WebRadioGroup1.ItemIndex := 0;
    WebRadioGroup1.Items.BeginUpdate;
    try
      WebRadioGroup1.Items.Clear;
      WebRadioGroup1.Items.Add('januari');
      WebRadioGroup1.Items.Add('februari');
      WebRadioGroup1.Items.Add('mars');
      WebRadioGroup1.Items.Add('april');
      WebRadioGroup1.Items.Add('maj');
      WebRadioGroup1.Items.Add('juni');
      WebRadioGroup1.Items.Add('juli');
      WebRadioGroup1.Items.Add('augusti');
      WebRadioGroup1.Items.Add('september');
      WebRadioGroup1.Items.Add('oktober');
      WebRadioGroup1.Items.Add('november');
      WebRadioGroup1.Items.Add('december');
    finally
      WebRadioGroup1.Items.EndUpdate;
    end;
    WebRadioGroup1.ParentFont := False;
    WebRadioGroup1.Role := '';
    WebRadioGroup1.TabOrder := 16;
    SetEvent(WebRadioGroup1, Self, 'OnChange', 'WebRadioGroup1Change');
    WebRadioGroup2.SetParentComponent(Self);
    WebRadioGroup2.Name := 'WebRadioGroup2';
    WebRadioGroup2.Left := 1336;
    WebRadioGroup2.Top := 520;
    WebRadioGroup2.Width := 145;
    WebRadioGroup2.Height := 177;
    WebRadioGroup2.Caption := 'V'#228'lj '#229'r';
    WebRadioGroup2.ChildOrder := 18;
    WebRadioGroup2.Columns := 1;
    WebRadioGroup2.Font.Charset := DEFAULT_CHARSET;
    WebRadioGroup2.Font.Color := clWindowText;
    WebRadioGroup2.Font.Height := -12;
    WebRadioGroup2.Font.Name := 'Segoe UI';
    WebRadioGroup2.Font.Style := [];
    WebRadioGroup2.ItemIndex := 0;
    WebRadioGroup2.Items.BeginUpdate;
    try
      WebRadioGroup2.Items.Clear;
      WebRadioGroup2.Items.Add('2021');
      WebRadioGroup2.Items.Add('2022');
      WebRadioGroup2.Items.Add('2023');
      WebRadioGroup2.Items.Add('2024');
      WebRadioGroup2.Items.Add('2025');
    finally
      WebRadioGroup2.Items.EndUpdate;
    end;
    WebRadioGroup2.ParentFont := False;
    WebRadioGroup2.Role := '';
    WebRadioGroup2.TabOrder := 17;
    SetEvent(WebRadioGroup2, Self, 'OnChange', 'WebRadioGroup2Change');
    WebTimerUtilityData.SetParentComponent(Self);
    WebTimerUtilityData.Name := 'WebTimerUtilityData';
    WebTimerUtilityData.Enabled := False;
    WebTimerUtilityData.Interval := 500;
    SetEvent(WebTimerUtilityData, Self, 'OnTimer', 'WebTimerUtilityDataTimer');
    WebTimerUtilityData.Left := 1296;
    WebTimerUtilityData.Top := 84;
    WebTimerAtPageStart.SetParentComponent(Self);
    WebTimerAtPageStart.Name := 'WebTimerAtPageStart';
    WebTimerAtPageStart.Enabled := False;
    SetEvent(WebTimerAtPageStart, Self, 'OnTimer', 'WebTimerAtPageStartTimer');
    WebTimerAtPageStart.Left := 1424;
    WebTimerAtPageStart.Top := 84;
  finally
    WebLabel4.AfterLoadDFMValues;
    btnExitPage.AfterLoadDFMValues;
    btnDataToday.AfterLoadDFMValues;
    btnDataOtherDate.AfterLoadDFMValues;
    btnDataPeriod.AfterLoadDFMValues;
    btnSaveToExcel.AfterLoadDFMValues;
    WebStringGrid1.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    btnClearMemo.AfterLoadDFMValues;
    cbVisaMemo.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
    WebDateTimePicker2.AfterLoadDFMValues;
    WebDateTimePicker3.AfterLoadDFMValues;
    edExcelFilnamn.AfterLoadDFMValues;
    RadioGroupBuildings.AfterLoadDFMValues;
    RadioGroupUtilities.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebRadioGroup1.AfterLoadDFMValues;
    WebRadioGroup2.AfterLoadDFMValues;
    WebTimerUtilityData.AfterLoadDFMValues;
    WebTimerAtPageStart.AfterLoadDFMValues;
  end;
end;

end.